import * as React from 'react'

import { useSelector } from 'react-redux';
import Policy from './Policy';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { AuthContext } from '../App';

const CartTotal = (props) => {
    const currentUser  = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;

    // let finapPr = props.totalPr + 20 + 15;
    const { cartLen, cartItems } = React.useContext(AuthContext);
    const [totalPr, setTotalPr] = React.useState(0);    
    React.useEffect(() => {
        window.scrollTo(0, 0);
        var tempTotalPr = 0;
        for (let i = 0; i < cartItems.length; i++) {
            tempTotalPr += cartItems[i].price * cartItems[i].quantity;
        }
        setTotalPr(tempTotalPr);
    }, [cartLen]);

    let hostUrl = 'https://stage-api.pinch.ai';
    const card = (
        <React.Fragment>
          <CardContent>
                <Typography className="cart-total-header" sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Price details ({cartLen} items)</b>
                </Typography>
              <div>
                <div className='price-item'>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Total</b>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        ${totalPr}
                    </Typography>
                </div>
                <div className='price-item'>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Shipping</b>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {totalPr < 100 ? <p> $15 </p>: <p><del>$15</del></p>}
                    </Typography>
                </div>
                <div className='price-item'>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Platform fee</b>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        $5
                    </Typography>
                </div>
                <div className='price-item'>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Tax</b>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        ${((totalPr * 5)*1.0)/100}
                    </Typography>
                </div>
                <div className='price-item total-summary'>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        <b>Total amount</b>
                    </Typography>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        ${Math.round(totalPr + (totalPr < 100 ? 20 : 0) + 5 + ((totalPr * 5)*1.0)/100)}
                    </Typography>
                </div>
                <br />
                <br />
                <div>
                    <Policy cartItems={cartItems}/>
                </div>
              </div>
          </CardContent>
        </React.Fragment>
    );

    return (

        <div>
            <Box className="price-details" sx={{ display: 'flex' }}>
                <Card variant="outlined">{card}</Card>
            </Box>

        </div>
    )
}

export default CartTotal

