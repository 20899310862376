import React, { useState, useEffect } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    CircularProgress,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Typography, Tooltip, Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../redux-state/UserState';
import EmptyOrder from './EmptyOrder';
import Alert from '@material-ui/lab/Alert';
import {Check, Close, Info, InfoOutlined} from "@mui/icons-material";
import {store} from "../productsStore/Store";


const useStyles = makeStyles((theme) => ({
    tableContainer: {
        maxWidth: '95%',
        // margin: '100px auto 100px auto',
    },
    table: {
        minWidth: 750,
    },
    tableHead: {
        backgroundColor: '#f5f5f5',
    },
    tableCell: {
        fontWeight: 'bold',
    },
    // loader: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     alignItems: 'center',
    //     height: '100vh',
    // },
    // dialog: {
    //     transition: 'width 0.5s ease-in-out',
    //     width: '200em',
    //     [theme.breakpoints.down('sm')]: {
    //         width: '90%',
    //     },
    //     '&.dialogClosing': {
    //         width: '0',
    //     },
    // },
    // dialogContent: {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     alignItems: 'center',
    //     padding: theme.spacing(2),
    // },
    dialogActions: {
        justifyContent: 'center',
        padding: theme.spacing(2),
    }
}));

const Orders = (props) => {
    const dispatch = useDispatch();
    const currentUser = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    
    const classes = useStyles();
    const [orders, setOrders] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [returnReason, setReturnReason] = useState('Bought by mistake');
    const [isOrdersLoading, setIsOrdersLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null);
    let hostUrl = 'https://stage-api.pinch.ai';
    const [successMessage, setSuccessMessage] = useState('');
    const [removeSubmit, setRemoveSubmit] = useState(true);
    const [isInReview, setIsInReview] = useState(false);
    const [showDialogLoader, setShowDialogLoader] = useState(false);
    const [showSubmit, setShowSubmit] = useState(true);
    const getProductName = (ids) => {
        const idsArray = ids.split(",");
        if(!idsArray[0].includes("SKU")) {
            return ids;
        }
        const items = store.filter(i => idsArray.indexOf(i.id) >= 0);
        return items.map(i => i.name).join(",");
    }

    const getStatusColor = (status) => {
        if (status === "Return Accepted") {
            return '#388e3c'
        } else if (status === "Return in Review") {
            return "#f57c00"
        } else if (status === "Return Denied") {
            return "#f50057"
        } else return "#3f51b5";
    }

    const getStatusIcon = (status) => {
        if (status === "Return Accepted") {
            return <Check style={{color: '#388e3c'}} />
        } else if (status === "Return in Review") {
            return <Info style={{color: "#f57c00"}} />
        } else if (status === "Return Denied") {
            return <Close style={{color:"#f50057" }} />
        } else return <Check style={{color: "#3f51b5"}} />;
    }
    
    const fetchOrders = async () => {
        // 1.
        // const cartOrderState = JSON.parse(localStorage.getItem('cartOrderState'));
        // console.log(cartOrderState);
        var myHeaders = new Headers();
        myHeaders.append("userId", currentUser.id);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };
        setIsOrdersLoading(true);

        const response = await fetch("https://api-demostore.pinch.ai/v1/order", requestOptions);
        const result = await response.json();       
        // setOrders(cartOrderState && cartOrderState.user && cartOrderState.user[currentUser.id] ? cartOrderState.user[currentUser.id].orders  : []);
        const sortedOrders = result.sort((a, b) => b.timestamp - a.timestamp);
        setIsOrdersLoading(false)
        setOrders(sortedOrders);
    };

    useEffect(() => {
        fetchOrders();
        console.log(orders.length == 0);
    }, []);

    const handleReturn = (order) => {
        setOpenDialog(true);
        setOrder(order);
        console.log(`Initiating return for order ${order.id}`);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setIsInReview(false);
        fetchOrders();
        setTimeout(() => {
            setShowSubmit(true);
            setSuccessMessage('');
            setErrorMessage('');
        }, 1000);
    };

    const [openErrorDialog, setOpenErrorDialog] = useState(false);

    const handleCloseErrorDialog = () => {
        setOpenErrorDialog(false);
    };

    const handleReturnReasonChange = (event) => {
        setReturnReason(event.target.value);
    };

    const handleSubmitReturn = async () => {

        setLoading(true); // Step 3: Set loading state to true

        // try {
        //     var myHeaders = new Headers();
        //     myHeaders.append("Accept", "*/*");
        //     myHeaders.append("x-merchant-id", "1406");
        //     myHeaders.append("Content-Type", "application/json");

        //     var raw = JSON.stringify({
        //     "orderID": order.id,
        //     "reason": returnReason,
        //     "customer": {
        //         "name": currentUser.name,
        //         "deviceFingerprints": [
        //         {
        //             "algorithm": "FingerprintJS",
        //             "fingerPrint": "230sdijad f0934rlkjs dfa"
        //         }
        //         ],
        //         "contactInformation": [
        //         {
        //             "contactType": "EMail",
        //             "address": currentUser.email
        //         },
        //         {
        //             "contactType": "ContactAddress",
        //             "addressType": "Home",
        //             "address": {
        //             "line1": currentUser.line1,
        //             "line2": currentUser.line2,
        //             "line3": null,
        //             "line4": null,
        //             "city": currentUser.city,
        //             "state": currentUser.state,
        //             "postalCode": currentUser.postal_code,
        //             "country": currentUser.country,
        //             "geoCode": {
        //                 "longitude": "17.44149578930582",
        //                 "latitude": "78.39321055250865"
        //             }
        //             }
        //         },
        //         {
        //             "contactType": "IPAddress",
        //             "address": "192.168.0.1"
        //         }
        //         ]
        //     },
        //     "returnedItems": [
        //         {
        //         "product": {
        //             "identifierType": "UPC",
        //             "identifier": "H1572ZY234",
        //             "category": "Shoes"
        //         },
        //         "count": 1,
        //         "policyID": order.policyId
        //         },
        //         {
        //         "product": {
        //             "identifierType": "UPC",
        //             "identifier": "TW276234Z",
        //             "category": "Cosmetics_body_care"
        //         },
        //         "count": 1,
        //         "policyID": order.policyId
        //         }
        //     ]
        //     });

        //     var requestOptions = {
        //     method: 'POST',
        //     headers: myHeaders,
        //     body: raw,
        //     redirect: 'follow'
        //     };
        //     console.log('Before return API call');
        //     const response = await fetch(hostUrl + "/return", requestOptions)
            
        //     // Handle API response
        //     if (response.ok) {
        //         console.log('Return submitted successfully');
        //         const result = await response.json();
        //         console.log('Return: ' + result);
        //         if (result.eligibleItems == null) {
        //             // if (order.policyName"30 days") {
        //             // Show dialog box with error message
        //             console.log("Return is ineligible");

        //             setOpenErrorDialog(true);
        //             setErrorMessage("Your return window has expired.");
        //             // return;
        //         } else {

        //             if (order.policyName.includes("30 days") && (Date.now() - order.timestamp) > 2592000000) {
        //                 setOpenErrorDialog(true);

        //                 setErrorMessage("Your return window has expired.");
        //             }
        //             // Please Please Please
        //             // Add removeOrder
        //             order.status = "Returned";
        //             dispatch(userActions.updateOrder({userId: currentUser.id, updatedOrder: order}));
        //             setSuccessMessage(result.eligibleItems[0].returnID);
        //             fetchOrders();
        //             console.log('Return: ' + result.eligibleItems);
        //             console.log('Your return window as expired. The item is not eligible for return. Please contact customer support.');
        //         }
                
        //     } else {
        //         console.log('Failed to submit return');
        //     }
        // } catch (error) {
        //     console.log('Error occurred while submitting return:', error);
        // }
        setShowSubmit(false);
        try {
            const myHeaders = new Headers();
            myHeaders.append("userId", currentUser.id);
            myHeaders.append("merchantId", "1406");
            myHeaders.append("Content-Type", "application/json");
    
            const raw = JSON.stringify({
                "id": order.id,
                "userId": null,
                "orderId": order.orderId,
                "price": order.price,
                "status": order.status,
                "returnId": null,
                "product": order.product,
                "productCategory": order.productCategory,
                "policyId": order.policyId,
                "policyName": order.policyName,
                "timestamp": order.timestamp,
                "reason": returnReason
            });
    
            const requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
            };
            console.log(raw);

            fetch("https://api-demostore.pinch.ai/v1/order/return", requestOptions)
                .then(res => res.text())
                .then(result => {
                    if (result !== "Success") {
                        console.log("Error result: " + result);
                        console.log('Failed to update order status to Returned');
                        if (result === "Return in Review") {
                            setIsInReview(true);
                        } else {
                            setErrorMessage(result)
                        }
                    } else {
                        console.log(result);

                        setSuccessMessage(order.policyName);
                    }
                }).catch(err => setLoading(false)).finally(() => {
                    setLoading(false);
                    setShowDialogLoader(false);
            });
        } catch(error) {
            console.log(error);
        }
        setTimeout(() => {
            // setOpenDialog(false);
            console.log('loader should stop');
            setLoading(false); // Step 5: Set loading state to false
            setRemoveSubmit(false);
        }, 1000);
    };

    return (
        <div>
            {isOrdersLoading ? (
                <div className="flex w-full justify-center py-10"><CircularProgress /></div>
            ) : (
                <>
                    <TableContainer component={Paper} className={classes.tableContainer}>
                        <Table className={classes.table}>
                            {orders.length == 0 ?
                                <EmptyOrder />:
                                <TableHead>
                                    <TableRow className={classes.tableHead}>
                                        <TableCell className={classes.tableCell}>Order ID</TableCell>
                                        <TableCell className={classes.tableCell}>Delivered On</TableCell>
                                        <TableCell className={classes.tableCell}>Products</TableCell>
                                        <TableCell className={classes.tableCell}>Price</TableCell>
                                        <TableCell className={classes.tableCell}>Status</TableCell>
                                        <TableCell className={classes.tableCell}>Action</TableCell>
                                    </TableRow>
                                </TableHead>
                            }
                            <TableBody>
                                {orders.map((order) => (
                                    <TableRow key={order.id}>
                                        <TableCell>{order.id}</TableCell>
                                        <TableCell>{new Date(order.timestamp).toLocaleDateString()}</TableCell>
                                        <TableCell>{getProductName(order.product)}</TableCell>
                                        <TableCell>{order.price}</TableCell>
                                        <TableCell>
                                            <Chip style={{
                                                background: "#fff",
                                                border: "1px solid",
                                                fontSize: "0.875rem",
                                                borderColor: getStatusColor(order.status),
                                                color: getStatusColor(order.status)
                                            }} label={order.status} icon={getStatusIcon(order.status)} />
                                            {order.status === "Return Denied" && (
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <Typography>Your return request is declined. Our records indicate an
                                                            extremely high rate of returns or abusive returns in the past. We
                                                            regularly monitor return activity for abuse and reserves the right
                                                            to limit returns or exchanges in all instances.</Typography>
                                                    </React.Fragment>
                                                } arrow>
                                                    <InfoOutlined style={{
                                                        marginLeft: "1rem",
                                                        color: '#f50057'
                                                    }}/>
                                                </Tooltip>
                                            )}
                                            {order.reviewed && order.status === "Return Accepted" && (
                                                <Tooltip title={
                                                    <React.Fragment>
                                                        <Typography>Your return request has been accepted. You can return your product for a different product, or a refund to the original payment method within 14 days of receiving your return.</Typography>
                                                    </React.Fragment>
                                                } arrow>
                                                    <InfoOutlined style={{
                                                        marginLeft: "1rem",
                                                        color: '#388e3c'
                                                    }}/>
                                                </Tooltip>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => handleReturn(order)}
                                                className={classes.actionButton + ' action-button'}
                                                disabled={order.status !== 'Delivered'}
                                            >
                                                Initiate Return
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    { order ?
                        <Dialog open={openDialog} onClose={handleCloseDialog} className={classes.dialog}>
                            <DialogContent>
                                {showDialogLoader && (<div className={classes.loader}>
                                    <CircularProgress/>
                                </div>)}
                                {!showDialogLoader && (successMessage || errorMessage) ? (<div>
                                    {successMessage ?
                                        <Alert severity="success"><b><h4>Your return has been accepted.</h4></b> <br/>
                                            <i>{successMessage}</i></Alert> :
                                        <Alert severity="error"><b><h4>Your return has been declined.</h4></b> <br/>
                                            <i>{errorMessage}</i></Alert>}
                                </div>) : isInReview ? (
                                        <Alert severity="warning"><b><h4>Your return request is being processed.</h4></b> <br/>
                                            <i>We will get back to you shortly on the status of your return. </i></Alert>
                                    ) :
                                    !showDialogLoader && (<FormControl>
                                        <RadioGroup
                                            aria-labelledby="demo-radio-buttons-group-label"
                                            defaultValue="Bought by mistake"
                                            name="radio-buttons-group"
                                            value={returnReason}
                                            onChange={handleReturnReasonChange}
                                        >
                                            <FormControlLabel value="Bought by mistake" control={<Radio/>}
                                                              label="Bought by mistake"/>
                                            <FormControlLabel value="Better price available" control={<Radio/>}
                                                              label="Better price available"/>
                                            <FormControlLabel value="Performance or quality not adequate" control={<Radio/>}
                                                              label="Performance or quality not adequate"/>
                                            <FormControlLabel value="Incompatible or not useful" control={<Radio/>}
                                                              label="Incompatible or not useful"/>
                                            <FormControlLabel value="Product damaged, but shipping box O" control={<Radio/>}
                                                              label="Product damaged, but shipping box OK"/>
                                            <FormControlLabel value="Item arrived too late" control={<Radio/>}
                                                              label="Item arrived too late"/>
                                            <FormControlLabel value="Missing parts or accessories" control={<Radio/>}
                                                              label="Missing parts or accessories"/>
                                            <FormControlLabel value="Both product and shipping box damaged"
                                                              control={<Radio/>}
                                                              label="Both product and shipping box damaged"/>
                                            <FormControlLabel value="Wrong item was sent" control={<Radio/>}
                                                              label="Wrong item was sent"/>
                                            <FormControlLabel value="Item defective or doesn't work" control={<Radio/>}
                                                              label="Item defective or doesn't work"/>
                                            <FormControlLabel value="Received extra item I didn't buy (no refund needed)"
                                                              control={<Radio/>}
                                                              label="Received extra item I didn't buy (no refund needed)"/>
                                            <FormControlLabel value="No longer needed" control={<Radio/>}
                                                              label="No longer needed"/>
                                            <FormControlLabel value="Didn't approve purchase" control={<Radio/>}
                                                              label="Didn't approve purchase"/>
                                            <FormControlLabel value="Inaccurate website description" control={<Radio/>}
                                                              label="Inaccurate website description"/>
                                        </RadioGroup>
                                    </FormControl>)}

                            </DialogContent>
                            {!showDialogLoader && (
                                <DialogActions>
                                    <Button onClick={handleCloseDialog} color="primary">
                                        Close
                                    </Button>
                                    {showSubmit && (<Button onClick={handleSubmitReturn} color="primary">
                                        Submit
                                    </Button>)}
                                </DialogActions>
                            )}
                        </Dialog> : null
                    }
                </>
                )}
        </div>
    );
};

export default Orders;
