import './App.css';
import { useState, createContext } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import NavBar from './components/NavBar';
import Body from './components/Body';
import SimpleSlider from './components/HeroCarousel';
import HeroSection from './components/HeroSection';
import JournalSection from './components/JournalSection';
import BsText from './components/BsText';
import SinglePage from "./components/SinglePage";
import JournalPage from "./components/JournalPage";
import Products from './components/Products';
import CartHold from './components/CartHold';
import MobileNav from './components/MobileNav';
import Orders from './components/Orders';
import LoginPage from './components/LoginPage';
import {Category} from "./components/Category";

const AuthContext = createContext();

function App() {

    // Initialize state for login status and current user
    const [isLoggedIn, setIsLoggedIn] = useState(false); // PLEASE CHANGE THIS TO FALSE
    const [currentUser, setCurrentUser] = useState(null);
    // const [cartLen, setCartLen] = useState(0);
    const [addressLine1, setAddressLine1] = useState('');
    const [addressLine2, setAddressLine2] = useState('');
    const [addressCity, setAddressCity] = useState('');
    const [addressState, setAddressState] = useState('');
    const [addressPostal, setAddressPostal] = useState('');
    const [addressCountry, setAddressCountry] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const [cartLen, setCartLen] = useState(0);

    const populateCart = (userId) => {
      const fetchCartItems = async () => {
        var myHeaders = new Headers();
        myHeaders.append("userId", userId);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const result = await fetch("https://api-demostore.pinch.ai/v1/item", requestOptions);
        const resultJson = await result.json();
        if (resultJson.length > 0) {
            var total = 0;
            resultJson.map((item) => {
                total += item.quantity;
            });
            setCartItems(resultJson);
            setCartLen(total);
        }
        console.log(resultJson);
      };
      fetchCartItems();
    };
  
    // Function to handle login
    const login = async (email, password) => {
      const loginUser = async () => {
        var requestOptions = {
          method: 'GET',
          redirect: 'follow'
        };
        
        const result = await fetch("https://api-demostore.pinch.ai/v1/user/login?email=" + email + "&password=" + password, requestOptions);

        const resultJson = await result.json();
        console.log(resultJson);
        if (resultJson.name) {
          console.log(result + " - User logged in successfully")
          setCurrentUser(resultJson);
          console.log("User set");
          setIsLoggedIn(true);
          localStorage.setItem('user-id', JSON.stringify(resultJson));
          populateCart(resultJson.id);
          return true;
        }

        return false;
      }
      return loginUser();
    };
  
    // Function to handle logout
    const logout = () => {
      setCurrentUser(null);
      setIsLoggedIn(false);
      localStorage.removeItem('user-id');
    };



  return (
      <div className={`App`}>
        <AuthContext.Provider value={{ isLoggedIn, currentUser, 
          login, logout, cartLen, cartItems, setCartItems, setCartLen, populateCart
          }}>
        <BrowserRouter>
          <Routes>
            <Route
              path="/"
              element={
                    localStorage.getItem('user-id') ? (
                      <>
                        <NavBar />
                        <SimpleSlider />
                        <HeroSection />
                        <BsText />
                        <Products />
                        <JournalSection />
                      </>
                    ) : (
                      <Navigate to="/login" />
                    )
              }
            />
            <Route path="/journal/april" element={<JournalPage />} />
            <Route
              path="/cart"
              element={
                    // isLoggedIn ? (
                    localStorage.getItem('user-id') ? (
                      <>
                        <NavBar />
                        <CartHold />
                      </>
                    ) : (
                      <Navigate to="/login" />
                    )
              }
            />
              <Route path="/category/:id" element={
                  localStorage.getItem('user-id') ? (
                      <>
                          <NavBar />
                          <Category />
                      </>
                  ) : (
                      <Navigate to="/login" />
                  )
              } />
            <Route
              path="/orders"
              element={
                localStorage.getItem('user-id') ? (
                      <>
                        <NavBar />
                        <Orders />
                      </>
                    ) : (
                      <Navigate to="/login" />
                    )
              }
            />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/:id" element={
            localStorage.getItem('user-id') ? (
              <>
                <NavBar />
                  <SinglePage />
              </>
            ) : (
              <Navigate to="/login" />
            )
            } />
          </Routes>
        </BrowserRouter>
        </AuthContext.Provider>
      </div>
  );
}

export default App;

// Export AuthContext for use in other components
export { AuthContext };