import * as React from 'react'
import { useState } from 'react';
import { useSelector } from 'react-redux';
import Cart from './Cart';
import CartPageFaq from './CartPageFaq';
import CartTotal from './CartTotal';
import EmptyCart from './EmptyCart';
import YouMayAlsoLike from './YouMayAlsoLike';
import "../styles/BestSellers.css";
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from '@material-ui/core';
import Box from '@mui/material/Box';
import { Link } from 'react-router-dom';
import { AuthContext } from '../App';
import { useEffect } from 'react';

const CartHold = () => {

    const [openDialog, setOpenDialog] = useState(false);

    const currentUser  = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
    console.log(currentUser);
    // const cartItems = useSelector((state) => state.user[currentUser.id].cartItems);//[currentUser.id].cartItems);
    // const [cartItems, setCartItems] = useState([]);
    const {cartLen, setCartLen, cartItems, setCartItems} = React.useContext(AuthContext); 
    // const cartLen = cartItems.length;

    const fetchCartItems = async () => {
        var myHeaders = new Headers();
        myHeaders.append("userId", currentUser.id);

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        const result = await fetch("https://api-demostore.pinch.ai/v1/item", requestOptions);
        const resultJson = await result.json();
        if (resultJson.length > 0) {
            var total = 0;
            resultJson.map((item) => {
                total += item.quantity;
            }
            );
        setCartLen(total);
        setCartItems(resultJson);
        console.log(resultJson);
        }
    };

    useEffect(() => {
        window.scrollTo(0, 0);

        fetchCartItems();
    }, [cartLen]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const totalPrice = cartItems.map(item => {
        return item.quantity * item.price;
    }).reduce((totalPrice, singleItemPrice) => totalPrice + singleItemPrice, 0);

    const card = (
        <React.Fragment>
          <CardContent className='card-content'>
              <div className='address-card'>
                <div>
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                    <b>Deliver to: {currentUser.name}, {currentUser.postalCode}</b>
                    </Typography>
                    <Typography sx={{ fontSize: 8 }} color="text.secondary" gutterBottom>
                        {currentUser.addressLine1 + ", " + currentUser.addressLine2 + ", " + currentUser.city + ", " + currentUser.postalCode + ", " + currentUser.state + ", " + currentUser.country} 
                    </Typography>
                </div>
              </div>
          </CardContent>
        </React.Fragment>
    );

    return (
        
        <div>
            {cartLen === 0 ? <EmptyCart /> : <>
                <Box sx={{ width: '100%'}}>
                    <Grid container className='cart-grid'>
                        <Grid item xs={8} className='cart-page-grid-item' >
                            <div>
                                <div className='cartList'>
                                <Card variant="outlined">{card}</Card>
                                    <Typography className='cart-header'><b>{cartLen} Items in the bag</b></Typography>
                                    {cartItems.map((item) => (
                                        <div>
                                            <Cart
                                                key={item.id}
                                                item={{
                                                    id: item.id,
                                                    itemId: item.itemId,
                                                    title: item.name,
                                                    quantity: item.quantity,
                                                    total: item.totalPrice,
                                                    price: item.price,
                                                    description: item.description,
                                                    image: item.imageTag,
                                                    cartLength: cartItems.length,
                                                }}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4} className='cart-total-div'>
                            <CartTotal totalPr={totalPrice} />
                        </Grid>
                    </Grid>

                    <Typography variant='h5' className=' similar-product relative'>SIMILAR PRODUCTS</Typography>
                    <YouMayAlsoLike />
                    <CartPageFaq />
                </Box>
            </>}
        </div>
    )
}

export default CartHold;