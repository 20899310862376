import * as React from 'react';
import { useSelector } from 'react-redux'
import { cartActions } from '../redux-state/CartState'
import { userActions } from '../redux-state/UserState';
import { useDispatch } from 'react-redux'

import { MdAdd } from "react-icons/md";
//import { GrFormSubtract } from "react-icons/gr"
import { RiSubtractFill } from "react-icons/ri"
import { Link } from 'react-router-dom';
import { AuthContext } from '../App';
import { Box, Button, ButtonGroup, Card, CardContent, Typography } from '@material-ui/core';


const Cart = (props) => {

  const { title, quantity, price, id, image, itemId, total, cartLength } = props.item;

  const currentUser  = localStorage.getItem('user-id') ? JSON.parse(localStorage.getItem('user-id')) : null;
  
  const [state, setState] = React.useState({ counter: quantity });

  const { setCartLen, setCartItems } = React.useContext(AuthContext);



  const removeItemFromCartHandler = () => {
    // dispatch(cartActions.removeItemFromCart(id));
    setState(state => ({ counter: state.counter - 1 }));
    const removeItem = async () => {
      var myHeaders = new Headers();
      myHeaders.append("userId", currentUser.id);
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
      };

      const result = await fetch("https://api-demostore.pinch.ai/v1/item/" + id, requestOptions);
      const resultJson = await result.json();
      if (resultJson.length > 0) {
          var total = 0;
          resultJson.map((item) => {
              total += item.quantity;
          }
          );
          console.log(total);
          setCartLen(total);
          setCartItems(resultJson);
          console.log("Item removed from cart");
      }
    };

    removeItem();

  }

  const addItemToCartHandler = () => {
    console.log("itemId: "+ id);
    const addItem = async () => {
      var myHeaders = new Headers();
      myHeaders.append("userId", currentUser.id);
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
          "userId": null,
          "itemId": itemId,
          "name": title,
          "description": "The number one facial cream out there.",
          "price": price,
          "quantity": 1,
          "imageTag": image
      });

      var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
      };

      const result = await fetch("https://api-demostore.pinch.ai/v1/item", requestOptions);
      const resultJson = await result.json();
      if (resultJson.length > 0) {
          var total = 0;
          resultJson.map((item) => {
              total += item.quantity;
          }
          );
          console.log(total);
          setCartLen(total);
          setCartItems(resultJson);
          console.log("Item added to cart");
      }
    };
    addItem();
    setState(state => ({ counter: state.counter + 1 }));
  }

  const displayCounter = state.counter > 0;

  
  const card = (
      <React.Fragment>
        <CardContent className='card-content'>
            <Link to={`/${id}`}>
              <img src={image} className="relative  fof w-44 rounded-lg cartImg" />
            </Link>
            <div>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                <b>{title}</b>
              </Typography>
              <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                ${price}
              </Typography>
              <ButtonGroup size="small" aria-label="small outlined button group">
                <Button onClick={removeItemFromCartHandler}>-</Button>
                {displayCounter && <Button>{state.counter}</Button>}
                {displayCounter && <Button onClick={addItemToCartHandler}>+</Button>}
              </ButtonGroup>
            </div>
        </CardContent>
      </React.Fragment>
  );


  return (
    <div >
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Card variant="outlined">{card}</Card>
      </Box>
    </div>


  )
}

export default Cart

